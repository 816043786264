var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import queryString from 'query-string';
import moment from 'moment';
import GeneralMessage from '../ui/general-message';
import Status from './status';
import Loader from '../ui/loader';
var globalStyles = require('../application/application.scss');
var styles = require('./claim-list.scss');
var statusStyles = require('./status.scss');
var ClaimList = /** @class */ (function (_super) {
    __extends(ClaimList, _super);
    function ClaimList(props) {
        var _this = _super.call(this, props) || this;
        _this.viewDetail = function (claim) {
            //Encoded to ensure characters like '+' are captured
            var id = encodeURIComponent(claim.id);
            _this.props.history.push({
                pathname: '/claims/summary',
                search: "id=" + id + "&vin=" + _this.state.vin + "&lastName=" + _this.state.lastName + "&zipCode=" + _this.state.zipCode
            });
        };
        _this.state = {
            vin: '',
            lastName: '',
            zipCode: '',
            claims: [],
            isLoading: true,
            isError: false
        };
        return _this;
    }
    ClaimList.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParams, claims, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParams = __rest(queryString.parse(this.props.location.search), []);
                        if (!queryParams.vin || !queryParams.lastName || !queryParams.zipCode) {
                            this.setState({ isError: true });
                        }
                        this.setState({
                            vin: queryParams.vin,
                            lastName: queryParams.lastName,
                            zipCode: queryParams.zipCode
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fetchClaims(queryParams)];
                    case 2:
                        claims = _a.sent();
                        this.setState({ isLoading: false, claims: claims });
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.setState({ isLoading: false, isError: true });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ClaimList.prototype.render = function () {
        var _this = this;
        var contents;
        if (this.state.isLoading) {
            contents = React.createElement(Loader, null);
        }
        else if (this.state.isError) {
            contents = (React.createElement(GeneralMessage, { type: "error", title: "System Error", description: 'An unexpected error has occurred. Please try again later.' }));
        }
        else if (!this.state.isLoading && this.state.claims.length === 0) {
            contents = (React.createElement(GeneralMessage, { type: "warning", title: "No Open Claims Found", description: "No open claims have been found.  Please contact the JNR hotline at 888.867.8032 for assistance" }));
        }
        else {
            contents = (React.createElement(React.Fragment, null,
                React.createElement("h3", null,
                    "My Submitted Claims",
                    React.createElement("br", null),
                    React.createElement("br", null)),
                React.createElement("p", { className: globalStyles.instructions },
                    "Status Guide:",
                    React.createElement("br", null)),
                React.createElement("div", { className: statusStyles.status + ' ' + statusStyles.inReview }, "In Review"),
                React.createElement("p", { className: globalStyles.instructions }, "Your claim has been submitted and an analyst is currently reviewing your application."),
                React.createElement("div", { className: statusStyles.status + ' ' + statusStyles.awaitingCustomer }, "Awaiting Customer"),
                React.createElement("p", { className: globalStyles.instructions }, "You were sent an email as your claim needs additional information to process your application. Please reply to the original email thread from the Mazda Customer Reimbursement Team and attach the information and documents requested."),
                React.createElement("div", { className: statusStyles.status + ' ' + statusStyles.paymentProcessing }, "Payment Processing"),
                React.createElement("p", { className: globalStyles.instructions }, "Your reimbursement reward card will be mailed approximately 3-4 weeks via U.S. Mail, plus mailing time from the date of the email stating your email is in the processing stage."),
                React.createElement("div", { className: statusStyles.status + ' ' + statusStyles.denied }, "Denied"),
                React.createElement("p", { className: globalStyles.instructions }, "Your application has been denied. Please check your email for the specific reason your claim was denied."),
                React.createElement("div", { className: statusStyles.status + ' ' + statusStyles.closed }, "Closed"),
                React.createElement("p", { className: globalStyles.instructions }, "Your claim has been paid and your Mazda Reimbursement reward card should arrive 10-14 days from the date of the automated email you received when your claim was put in Closed Status."),
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Status"),
                            React.createElement("th", null, "Recall Campaign"),
                            React.createElement("th", null, "Submitted Date"))),
                    React.createElement("tbody", null, this.state.claims.map(function (claim) { return (React.createElement("tr", { key: claim.id, tabIndex: 0, onKeyPress: function (e) {
                            e.key === 'Enter' && _this.viewDetail(claim);
                        }, onClick: function () { return _this.viewDetail(claim); }, "data-test": 'claim_list_item' },
                        React.createElement("td", null,
                            React.createElement(Status, { status: claim.status })),
                        React.createElement("td", null, claim.campaign.projectName),
                        React.createElement("td", null, moment(claim.created).format("MM-DD-YYYY")))); })))));
        }
        return (React.createElement("article", { className: styles.claimListContainer },
            React.createElement("section", null, contents)));
    };
    ClaimList.prototype.fetchClaims = function (queryParams) {
        return __awaiter(this, void 0, void 0, function () {
            var vin, lastName, zipCode, response, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        vin = queryParams.vin, lastName = queryParams.lastName, zipCode = queryParams.zipCode;
                        return [4 /*yield*/, axios.get('/api/claims-list', { params: { vin: vin, lastName: lastName, zipCode: zipCode } })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data.claims];
                    case 2:
                        err_2 = _a.sent();
                        this.setState({ isError: true });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ClaimList;
}(React.Component));
export default withRouter(ClaimList);
