var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { connect } from 'formik';
import * as Yup from 'yup';
import FormField, { RadialSelection } from '../ui/form-field';
import FileUpload from './file-upload';
import FieldError from '../ui/field-error';
import PreventTransitionPrompt from '../ui/prevent-transition-prompt';
import Button from '../ui/button';
import { usStates } from '../utils/address/us-states';
import { canadaProvinces } from "../utils/address/canada-provinces";
import { UNITED_STATES, CANADA, OTHER_COUNTRY, ZIP_CODE_MAX, ZIP_CODE_MIN, JIRA_TEXT_MAX_CHARACTERS, MIN_FILES, MAX_FILES, TOTAL_FILES, FILE_TYPES, MAX_FILE_SIZE_MB, UPLOAD_INVALID_CHARACTERS, MILEAGE_MIN, MILEAGE_MAX } from "../constants";
import numWords from 'num-words';
import Checkbox from "../ui/checkbox";
import { CampaignOptions } from '../utils/campaign-options';
import { getCampaignById } from '../utils/get-campaign';
import Modal from "../ui/modal";
var globalStyles = require('../application/application.scss');
var styles = require('./contact-form.scss');
export var ContactFormSchema = Yup.object().shape({
    mileage: Yup.string()
        .required('Mileage is a required field')
        .min(MILEAGE_MIN, "Mileage must be at least " + numWords(MILEAGE_MIN) + " digits")
        .max(MILEAGE_MAX, "Mileage must be no more than " + numWords(MILEAGE_MAX) + " digits")
        .test('not-zero-one-or-999999', 'Value entered is not allowed.', function (value) {
        var numericValue = parseInt(value, 10);
        return numericValue !== 0 && numericValue !== 1 && numericValue !== 999999;
    }),
    firstName: Yup.string()
        .required('First name is a required field'),
    lastName: Yup.string()
        .required('Last name is a required field'),
    addressOne: Yup.string()
        .required('Street name is a required'),
    addressTwo: Yup.string(),
    city: Yup.string()
        .required('City is a required field'),
    state: Yup.string()
        .required('State is a required field')
        .length(2),
    zipCode: Yup.string()
        .required('Zip code is a required field')
        .min(ZIP_CODE_MIN, "Zip code must be at least " + numWords(ZIP_CODE_MIN) + " digits")
        .max(ZIP_CODE_MAX, "Zip code must be no more than " + numWords(ZIP_CODE_MAX) + " digits"),
    country: Yup.string()
        .required(),
    phoneNumber: Yup.string()
        .required('Phone number is a required field')
        .matches(/^\d{10}$/, 'Phone number must match the pattern (999)999-9999'),
    emailAddress: Yup.string()
        .email('Email Address must be valid')
        .required('Email is a required field'),
    amount: Yup.string()
        .matches(/^\d+(\.\d{2})?$/, 'Please enter dollar amount in xxx.xx format')
        .required('Reimbursement amount is a required field'),
    files: Yup.array()
        .min(MIN_FILES)
        .max(TOTAL_FILES)
});
var ContactForm = /** @class */ (function (_super) {
    __extends(ContactForm, _super);
    function ContactForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showModal: true,
            country: null,
            amount: null,
            acknowledge_checkbox1: false,
            acknowledge_checkbox2: false
        };
        _this.componentDidMount = function () {
            var SpecificCampaignOptions = _this.getCampaignOptions();
            _this.initCountrySelection(SpecificCampaignOptions);
            _this.initReimbursementAmount(SpecificCampaignOptions);
        };
        _this.getCampaignOptions = function () {
            var campaignId = _this.props.formik.values.campaignId;
            var projectKey = getCampaignById(campaignId, _this.props.campaigns).projectKey;
            var SpecificCampaignOptions = new CampaignOptions(projectKey);
            return SpecificCampaignOptions;
        };
        _this.initCountrySelection = function (SpecificCampaignOptions) {
            if (SpecificCampaignOptions.getCountry()) {
                _this.setCountrySelection(SpecificCampaignOptions.getCountry());
            }
        };
        _this.initReimbursementAmount = function (SpecificCampaignOptions) {
            if (SpecificCampaignOptions.getReimbursementAmount()) {
                _this.setState({ amount: SpecificCampaignOptions.getReimbursementAmount() });
                _this.props.formik.setFieldValue('amount', SpecificCampaignOptions.getReimbursementAmount());
            }
        };
        _this.setCountrySelection = function (country) {
            _this.setState({ country: country });
            _this.props.formik.setFieldValue('country', country !== OTHER_COUNTRY ? country : '', false);
        };
        return _this;
    }
    ContactForm.prototype.render = function () {
        var _this = this;
        var _a = this.props.formik, errors = _a.errors, values = _a.values, isSubmitting = _a.isSubmitting, setFieldValue = _a.setFieldValue;
        var isValid = function (propName) {
            return !errors[propName] && values[propName] != '';
        };
        return (React.createElement("section", { className: styles.contactForm, "data-test": "claim_submission_form" },
            React.createElement(PreventTransitionPrompt, { when: true, title: "Exiting this screen will delete information entered", message: "Do you want to exit this screen?", redirectPath: "/" }),
            React.createElement("fieldset", { "aria-disabled": isSubmitting, disabled: isSubmitting },
                React.createElement("div", null,
                    React.createElement("h3", null, "Mailing Address"),
                    React.createElement("p", { className: globalStyles.instructions },
                        "All fields are required except where noted.",
                        React.createElement("br", null),
                        "No special characters or punctuation allowed."),
                    React.createElement(FormField, { name: "mileage", label: "Mileage at time of repair", type: "text", inputType: "numberField", autoFocus: true, "data-test": 'mileage' }),
                    React.createElement(FormField, { name: "firstName", label: "First Name", type: "text", "data-test": 'first_name' }),
                    React.createElement(FormField, { name: "lastName", label: "Last Name", type: "text", "data-test": 'last_name' }),
                    React.createElement(FormField, { name: "addressOne", label: "Address Line 1", type: "text", "data-test": 'address_1' }),
                    React.createElement(FormField, { name: "addressTwo", label: "Address Line 2 (optional)", type: "text", "data-test": 'address_2' }),
                    React.createElement(FormField, { name: "city", label: "City", type: "text", "data-test": 'city' }),
                    React.createElement(RadialSelection, { name: "country-selection", label: "Select Country", selection: function (country) { _this.setCountrySelection(country); }, options: [UNITED_STATES, CANADA, OTHER_COUNTRY], selected: this.state.country, "data-test": 'country' }),
                    React.createElement("div", { className: styles.inputGroup },
                        this.state.country === UNITED_STATES && (React.createElement("div", null,
                            React.createElement(FormField, { name: "state", label: "State", component: "select", "data-test": 'state' },
                                React.createElement("option", null),
                                usStates.map(function (state) { return (React.createElement("option", { key: state }, state)); })))),
                        this.state.country === CANADA && (React.createElement("div", null,
                            React.createElement(FormField, { name: "state", label: "Province", component: "select" },
                                React.createElement("option", null),
                                canadaProvinces.map(function (state) { return (React.createElement("option", { key: state }, state)); })))),
                        this.state.country === OTHER_COUNTRY && (React.createElement("div", null,
                            React.createElement(FormField, { name: "country", label: "Country", type: "text" }))),
                        this.state.country === OTHER_COUNTRY && (React.createElement("div", null,
                            React.createElement(FormField, { name: "state", label: "State or Province", type: "text", size: "2", maxlength: "2" }))),
                        React.createElement("div", null,
                            React.createElement(FormField, { name: "zipCode", label: this.state.country === CANADA ? "Postal Code" : "Zip Code", type: "text", size: ZIP_CODE_MAX, maxlength: ZIP_CODE_MAX, "data-test": 'zip_code' })))),
                React.createElement("div", null,
                    React.createElement("h3", null, "Contact Info"),
                    React.createElement(FormField, { name: "phoneNumber", label: "Phone Number", type: "text", inputType: "numberField", format: "(###) ###-####", "data-test": 'phone_number' }),
                    React.createElement(FormField, { name: "emailAddress", label: "Email Address", type: "text", "data-test": 'email_address' })),
                React.createElement("div", null,
                    React.createElement("h3", null, "Reimbursement"),
                    React.createElement(FormField, { name: "amount", label: "Reimbursement Amount", type: "text", inputType: "numberField", thousandSeparator: true, prefix: '$', decimalScale: 2, "data-test": 'amount', placeholder: this.state.amount && "$" + this.state.amount, disabled: this.state.amount ? true : false }),
                    React.createElement("span", { className: globalStyles.instructions },
                        React.createElement("strong", null, "Amount to be entered must be in US Dollars (USD) only. If you paid for repairs in non-US Dollars please convert the amount to USD."))),
                React.createElement("div", null,
                    React.createElement("label", null, "Upload all required documentation. Multiple files are acceptable."),
                    React.createElement("span", { className: globalStyles.instructions },
                        React.createElement("strong", null,
                            "Needs at least ",
                            numWords(MIN_FILES),
                            " and no more than ",
                            numWords(MAX_FILES),
                            " files",
                            React.createElement("br", null),
                            "Each file can be no larger than ",
                            MAX_FILE_SIZE_MB,
                            " megabytes",
                            React.createElement("br", null),
                            "File types allowed: ",
                            FILE_TYPES.join(', '),
                            React.createElement("br", null),
                            "File names cannot contain any of these characters: ",
                            UPLOAD_INVALID_CHARACTERS.join(', '))),
                    React.createElement(FileUpload, { minFiles: MIN_FILES, maxFiles: MAX_FILES }),
                    React.createElement(FieldError, { name: "files" })),
                React.createElement(Checkbox, { checked: values.soldVehicle !== 'NO', dataTest: "single_owner", action: function () { return setFieldValue('soldVehicle', values.soldVehicle === 'NO' ? 'YES' : 'NO', false); }, text: "Please check this box if you no longer own your Mazda vehicle" }),
                React.createElement("div", null,
                    React.createElement(FormField, { name: "additionalInformation", label: "Additional Information", type: "text", maxlength: JIRA_TEXT_MAX_CHARACTERS, "data-test": "additional_information" }),
                    React.createElement("span", { className: globalStyles.instructions },
                        React.createElement("strong", null,
                            "Enter any additional information related to your reimbursement. Note, box limit is ",
                            JIRA_TEXT_MAX_CHARACTERS,
                            " characters. If necessary please document in a Word document or similar and attach to your claim.")))),
            React.createElement(Button, { variant: "primary", type: "submit", disabled: !isValid('mileage') ||
                    !isValid('firstName') ||
                    !isValid('lastName') ||
                    !isValid('addressOne') ||
                    !isValid('city') ||
                    !isValid('state') ||
                    !isValid('zipCode') ||
                    !isValid('phoneNumber') ||
                    !isValid('amount') ||
                    !!errors.addressTwo ||
                    !!errors.emailAddress ||
                    !!errors.files ||
                    isSubmitting, onClick: this.props.updateProgress, "data-test": 'submit' }, "Continue"),
            React.createElement(Modal, { showModal: this.state.showModal, onConfirm: function () { }, hideOkButton: true },
                React.createElement("div", null, "To avoid delays with your claim payments, please provide Proof of payment for repairs completed (cash or credit card receipt, or cancelled check copy). For cash paid, a stamped paid receipt from the repair facility is required."),
                React.createElement("br", null),
                React.createElement("div", null, "Additional information may be required if the repair order or invoice receipt are unclear about whether the repair addresses the Campaign. For a replacement vehicle part, the receipt must identify the item and the total amout paid."),
                React.createElement("br", null),
                React.createElement("div", null, "Note: Mazda dealers are independent businesses and do not share proof of payment and customer paid repair orders with Mazda corporate."),
                React.createElement("br", null),
                React.createElement(Checkbox, { dataTest: "acknowledge_checkbox1", checked: this.state.acknowledge_checkbox1, action: function () { return _this.setState({ acknowledge_checkbox1: !_this.state.acknowledge_checkbox1 }); }, text: "I agree to provide proof of payment for repairs." }),
                React.createElement(Checkbox, { dataTest: "acknowledge_checkbox2", checked: this.state.acknowledge_checkbox2, action: function () { return _this.setState({ acknowledge_checkbox2: !_this.state.acknowledge_checkbox2 }); }, text: "I understand repairs not covered by this campaign will be denied." }),
                React.createElement("br", null),
                React.createElement("div", { style: { display: 'flex', gap: '10px', justifyContent: 'center' } },
                    React.createElement(Button, { variant: "primary", type: "button", disabled: !this.state.acknowledge_checkbox1 || !this.state.acknowledge_checkbox2, onClick: function () {
                            _this.setState({ showModal: false });
                        } }, "Agree and Continue"),
                    React.createElement(Button, { variant: "primary", type: "button", onClick: function () {
                            _this.setState({ showModal: false });
                            _this.props.cancelModal();
                        } }, "Cancel")))));
    };
    return ContactForm;
}(React.Component));
export default connect(ContactForm);
