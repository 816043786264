var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Redirect, withRouter } from 'react-router';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Consumer } from '../application/vin-context';
import FormField from '../ui/form-field';
import Button from '../ui/button';
import { ZIP_CODE_MAX, ZIP_CODE_MIN } from "../constants";
import numWords from 'num-words';
var styles = require('../application/homepage.scss');
var FormSchema = Yup.object().shape({
    vin: Yup.string()
        .matches(/[A-Z0-9]{17}/i, 'Please enter exactly 17 letters/digits')
        .required('Please enter your VIN'),
    lastName: Yup.string().required('Last Name is a required field'),
    zipCode: Yup.string()
        .required('Zip code is a required field')
        .min(ZIP_CODE_MIN, "Zip code must be at least " + numWords(ZIP_CODE_MIN) + " digits")
        .max(ZIP_CODE_MAX, "Zip code must be no more than " + numWords(ZIP_CODE_MAX) + " digits"),
});
var CheckStatusForm = /** @class */ (function (_super) {
    __extends(CheckStatusForm, _super);
    function CheckStatusForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isError: false
        };
        return _this;
    }
    CheckStatusForm.prototype.render = function () {
        var _this = this;
        if (this.state.isError) {
            return React.createElement(Redirect, { to: "/error" });
        }
        return (React.createElement(Consumer, null, function (context) {
            return (React.createElement(Formik, { initialValues: { vin: '', lastName: '', zipCode: '' }, validationSchema: FormSchema, onSubmit: function (values, formikActions) { return _this.checkStatus(values, formikActions, context); } }, function (_a) {
                var isSubmitting = _a.isSubmitting, isValid = _a.isValid, status = _a.status;
                return (React.createElement("div", { className: styles.lookupContainer },
                    React.createElement("h3", null, "Please enter the information below to check the status of your Customer Reimbursement application"),
                    React.createElement(Form, { className: styles.lookupForm },
                        React.createElement(FormField, { name: "vin", label: "VIN", type: "text", maxLength: "17" }),
                        React.createElement(FormField, { name: "lastName", label: "Last Name", type: "text" }),
                        React.createElement(FormField, { name: "zipCode", label: "Zip Code", type: "text", maxlength: ZIP_CODE_MAX }),
                        React.createElement(Button, { variant: "primary", type: "submit", disabled: isSubmitting || !isValid }, "Look Up"),
                        React.createElement("div", null, status && status.error))));
            }));
        }));
    };
    CheckStatusForm.prototype.checkStatus = function (_a, formikActions, context) {
        var vin = _a.vin, lastName = _a.lastName, zipCode = _a.zipCode;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                try {
                    context.update(vin);
                    this.props.history.push({ pathname: '/claims', search: "?vin=" + vin + "&lastName=" + lastName + "&zipCode=" + zipCode });
                }
                catch (err) {
                    if (err.response) {
                        formikActions.setFieldError('vin', err.response.data.error.message);
                    }
                    else {
                        this.setState({ isError: true });
                    }
                }
                finally {
                    formikActions.setSubmitting(false);
                }
                return [2 /*return*/];
            });
        });
    };
    return CheckStatusForm;
}(React.Component));
export default withRouter(CheckStatusForm);
