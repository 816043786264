import * as React from 'react';
import Button from './button';
var styles = require('./modal.scss');
var Modal = function (props) {
    return (React.createElement("div", { id: styles.modal, className: props.showModal ? styles.showModal : styles.hideModal, "data-test": props.dataTestPrefix + '_modal' },
        React.createElement("div", { className: styles.modalContent },
            props.children,
            React.createElement("div", { className: styles.footer }, props.onCancel ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "tertiary", onClick: props.onCancel, "data-test": props.dataTestPrefix + '_no_button' }, "No"),
                React.createElement(Button, { variant: "tertiary", onClick: props.onConfirm, autoFocus: true, "data-test": props.dataTestPrefix + '_yes_button' }, "Yes"))) : (!props.hideOkButton && ( // Hide the Ok button if hideOkButton is true
            React.createElement(Button, { variant: "tertiary", onClick: props.onConfirm, autoFocus: true, "data-test": props.dataTestPrefix + '_button' }, "Ok")))))));
};
export default Modal;
