import React from 'react';
export var JIRA_TEXT_MAX_CHARACTERS = 255;
export var MIN_FILES = 1;
export var MAX_FILES = 6;
export var TOTAL_FILES = 7;
export var MIN_FILE_SIZE_BYTE = 1;
export var MAX_FILE_SIZE_MB = 5;
export var FILE_TYPES = [
    '.jpg',
    '.jpeg',
    '.png',
    '.tif',
    '.tiff',
    '.bmp',
    '.dib',
    '.pdf',
    '.txt',
    '.doc',
    '.docx'
];
export var UPLOAD_INVALID_CHARACTERS = [
    '\\',
    '/',
    '|',
    '"',
    '<',
    '>',
    '%',
    '&',
    '$',
    '@',
    '#',
    '{',
    '}',
    '[',
    ']',
    '?',
    '!',
    '*',
    '^'
];
export var DISCLAIMER_FILENAME = 'Disclaimer.pdf';
export var DISCLAIMER_MIMETYPE = 'application/pdf';
export var RENAMED_DISCLAIMER_FILENAME = 'Disclaimer_client.pdf';
export var UNITED_STATES = 'United States';
export var CANADA = 'Canada';
export var OTHER_COUNTRY = 'Other';
export var ZIP_CODE_MIN = 5;
export var ZIP_CODE_MAX = 10;
export var MILEAGE_MIN = 1;
export var MILEAGE_MAX = 6;
export var CUSTOMER_SERVICE_NUMBER = '1-800-222-5500';
export var MAZDA_DEALER_LOOKUP_URL = 'https://www.mazdausa.com/find-a-dealer';
export var OPEN_RECALLS_MESSAGE = function () { return (React.createElement(React.Fragment, null,
    "Your vehicle has one or more campaign(s) in Open status. Please visit your nearest Mazda dealer to have these campaign(s) completed. Please click",
    ' ',
    React.createElement("a", { href: MAZDA_DEALER_LOOKUP_URL, target: "_blank" }, "here"),
    ' ',
    "to find your nearest Mazda dealership to make an appointment to repair your vehicle. If you have any questions about this campaign or the status, please contact Mazda Customer Experience at ",
    CUSTOMER_SERVICE_NUMBER,
    ", Option #4")); };
export var CLAIM_SUCCESS_MESSAGE = 'Your claim will be reviewed and you will receive a response within 30 calendar days from the date of submission as to the status of your application.';
export var CLAIM_ERROR_MESSAGE = "There was an error submitting your claim. Please try to submit your claim again or contact the Mazda Customer Experience Center at " + CUSTOMER_SERVICE_NUMBER + ", Option #6.";
export var NO_CAMPAIGNS_MESSAGE = "No available Customer Reimbursement campaigns were found for the supplied VIN.   Please contact Mazda Customer Experience at " + CUSTOMER_SERVICE_NUMBER + ", Option #4 if you have any questions about the listed recall(s) or special service program(s) below.";
export var PROCESSING_ERROR_MESSAGE = "There was an issue with your request. Please try again later or contact customer support team " + CUSTOMER_SERVICE_NUMBER + ".";
export var INVALID_VIN_MESSAGE = "Bad VIN: You have entered an Invalid VIN. Please re-enter your VIN or if sold new outside the USA, please contact the original distributor for your vehicle.";
export var NON_US_VIN_MESSAGE = "Non-US Vin: You have entered a Non-US VIN. Please re-enter your VIN or if sold new outside the USA, please contact the original distributor for your vehicle.";
export var NO_RECALLS_MESSAGE = "No available recalls were found for the supplied VIN. If this message is in error please contact customer support at " + CUSTOMER_SERVICE_NUMBER;
export var CAMPAIGN_OPEN_RECALL_MESSAGE = 'Your vehicle has not been serviced by a Mazda dealer under this campaign yet. Your reimbursement claim will not be approved until the vehicle has been serviced at a Mazda Dealer. Selecting the button below is an acknowledgement of this notice. If you no longer own your vehicle, please check the box when filling out the form indicating you no longer own your Mazda vehicle.';
export var NO_REMEDY_RECALLS_MESSAGE = "Your vehicle has a recall in Not Launched status.  If you are experiencing concerns related to this recall, please contact your nearest Mazda dealership about repairing your vehicle under this recall.   If you have any questions about this recall, please contact Mazda Customer Experience at " + CUSTOMER_SERVICE_NUMBER + ", Option #4.";
export var FILE_ERROR_MESSAGE = "The following file(s) could not be attached. This may be due to the file(s) being too large (over " + MAX_FILE_SIZE_MB + "MB max), are blank, or the file name containing special characters.";
export var UPLOAD_ERROR_ASSISTANCE_MESSAGE = "If you need further assistance please contact the Mazda Customer Experience Center at " + CUSTOMER_SERVICE_NUMBER + ", Option #6.";
export var GENERAL_ERROR_PAGE_MESSAGE = "The website application has encountered an error. Please contact Mazda Customer Experience at " + CUSTOMER_SERVICE_NUMBER + ", Option #6 with questions about your claim.";
